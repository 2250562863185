<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <img src="@/assets/dim.png" alt="" width="70" height="70" class="d-inline-block align-text-top">
        <h3>Boletería DIM</h3>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
            </li>
          </ul>
          <a class="nav-link active text-muted" aria-current="page" href="#">{{user.first_name}} {{user.last_name}}</a>
          <button class="btn btn-outline-danger" type="button" v-on:click="logout()">Salir</button>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

export default {
  name: 'Header',
  data: function () {
    return {
      user: JSON.parse(localStorage.user_info)
    }
  },
  methods: {
    logout () {
      this.$swal({
        title: '¿Seguro que deseas salir?',
        showDenyButton: true,
        confirmButtonText: 'Si',
        icon: 'question',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear()
          this.$router.push('/')
        }
      })
    }
  }
}
</script>
